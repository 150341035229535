
const products =  [ 
    {
        id: 1,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/T_6_front-300x300.jpg",
        productName:"Woo Ninja",
        productPrice:10
    },
    {
        id: 2,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/hoodie_7_front-300x300.jpg",
        productName:"Woo Ninja",
        productPrice:15
    },
    {
        id: 3,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/hoodie_2_front.jpg",
        productName:"Woo Ninja",
        productPrice:26
    },
    {
        id: 4,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/hoodie_3_front-300x300.jpg",
        productName:"Woo Ninja",
        productPrice:12
    },
    {
        id: 5,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/hoodie_3_front-300x300.jpg",
        productName:"Woo Ninja",
        productPrice:30
    },
    {
        id: 6,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/hoodie_3_front-300x300.jpg",
        productName:"Woo Ninja",
        productPrice:43
    },
    {
        id: 7,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/hoodie_3_front-300x300.jpg",
        productName:"Woo Ninja",
        productPrice:37
    },
    {
        id: 8,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/T_6_front-300x300.jpg",
        productName:"Woo Ninja",
        productPrice:13
    },
    {
        id: 9,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/T_6_front-300x300.jpg",
        productName:"Woo Ninja",
        productPrice:17
    },
    {
        id: 10,
        productImage:"https://demo.colorlib.com/shapely/wp-content/uploads/sites/59/2013/06/T_6_front-300x300.jpg",
        productName:"Woo Ninja",
        productPrice:22
    },
   

]

export default products;